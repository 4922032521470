<template>
  <div id="dashboard">
      
      
        <div class="col-lg-12 col-md-12 col-sm-12">
          
            <div class="vh-100">
    <b-table striped hover :fields="tablaSucursales.columns" :items="tablaSucursales.rows" responsive >
      <template #cell(estado)="data">
        <div v-if="data.item.estado == 0" >
          <span :id="'popover-' + data.item.clisuc_codigo" class="label label-warning" >Desconectado <b-icon icon="emoji-frown-fill" aria-hidden="true"></b-icon></span>
            <b-popover :target="'popover-' + data.item.clisuc_codigo" triggers="hover" placement="top">
              {{data.item.tiempoDesconectado}}
            </b-popover>
        </div>
        <div v-else>
          <span class="label label-success" >En línea</span>
        </div>
      </template>
      <template #cell(audio)="data">
        <div v-if="data.item.estado != 0 && data.item.audio.length>0">
          <audio :src="data.item.audio" controls></audio>
        </div>
        
      </template>
    </b-table>
  </div>
            <template slot="header">
              <p class="d-inline">{{$t('dashboard.sucursales.cantConectadas', {count: tablaSucursales.rows.filter(suc=>suc.estado==1).length})}}</p>
            </template>
         
          
        </div>

       
      </div>
    
  
</template>
<script>

import SucursalServices from '../services/SucursalServices'
import UserServices from '../services/UserServices'
import VueBootstrap4Table from 'vue-bootstrap4-table'
import FacturaServices from '../services/FacturaServices'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import clienteModoSpotService from '../services/ClienteModoReproduccionSpot'
import SpotServices from '../services/SpotServices'
import ClientePaqueteService from '../services/ClientePaqueteServices'
import LocutorService from '../services/LocutorServices'

import moment from 'moment'
import { BaseAlert } from '@/components'

export default {
  components: {
    VueBootstrap4Table,
    ScaleLoader,
    BaseAlert
  },
  data () {
    const i18labels = this.$t('modulo_sucursales.principal')
    const colnames = i18labels.tabla.columnas
    const i18labels1 = this.$t('modulo_spots.biblioteca_spots')
    const colnames1 = i18labels1.tabla_spots.columnas

    return {
      user: null,
      crearSucursales: false,
      cantSucursales: null,
      cantSucursalesCon: 0,
      isLoading: false,
      modoRepSpot: '',
      sucursales: [],
      tablaSucursales: {
        columns: [
          {
            label: colnames.clisuc_nombre,
            key: 'clisuc_nombre',
          
          },
          {
            label: colnames.progMusica,
            key: 'clipro_nombre'
          },
          {
            label: colnames.progSpot,
            key: 'progSpot'
          },
          {
            label: colnames.estado,
            key: 'estado'
          },
          {
            label: "Escuchar",
            key: 'audio'
          }
        ],
        rows: [],
        config: {
          show_refresh_button: false,
          show_reset_button: false,
          pagination: true,
          per_page: 10,
          pagination_info: false,
          global_search: {
            placeholder: 'Buscar'
          },
          highlight_row_hover: true,
          preservePageOnDataChange: true,
          highlight_row_hover_color: '#003962'
        }
      },
      tablaSpotsVencimiento: {
        columns: [
          {
            label: colnames1.spo_nombre,
            name: 'spo_nombre'
          },
          {
            label: colnames1.fechaVenc,
            name: 'spo_fecfin'
          }
        ],
        rows: [],
        config: {
          show_refresh_button: false,
          show_reset_button: false,
          pagination: true,
          per_page: 3,
          pagination_info: false,
          global_search: {
            visibility: false
          },
          highlight_row_hover: true,
          preservePageOnDataChange: true,
          highlight_row_hover_color: '#003962'
        }
      },
      facturas: [],
      ultimaFactura: null,
      haySpotsVencidos: false,
      paqueteContratado: null,
      cantMaxPedidos: null,
      pedidosDisponibles: null

    }
  },
  computed: {

  },
  methods: {
    getCurrentUser () {
      const cli = JSON.parse(UserServices.current().Cliente)
      this.$store.state.cliente = cli
      this.user = cli
    },
    actualizarEstadoSucursal () {
      this.timer = window.setInterval(() => {
        this.tablaSucursales.rows.forEach(row => {
          SucursalServices.estadoSucursal(row.username).then(response => {
            row.estado = response.estado
            row.tiempoDesconectado = this.tiempoDesconectado(response.diferencia)
          })
        })
      }, 600000)},
    cliSucursalesGetByCli () {
      this.isLoading = true
      SucursalServices.getcliSucursalByCliente().then((res) => {
        
        this.sucursales = res
        
        const cantSucursales = this.sucursales.length

        this.chequearLimiteSucursales(cantSucursales)

        if (cantSucursales > 0) {
          this.inicializar()
        } else {
          this.crearSucursales = true
        }

         this.isLoading = false
      })
    },
    escuchar(url){
      const audio = new Audio(url);
      audio.play();
    },
    cargarConenidoTabla () {
      const auxRes = []
      let progSpot
      // Armo un json auxiliar agregando, por cada sucursal traida, el estado
      
      this.sucursales.forEach(element => {
        SucursalServices.estadoSucursal(element.username).then(response => {
          SucursalServices.getprogSpotBySuc(element.clisuc_nombre).then(resp => {
            if(resp.length>0){
              progSpot = resp[0].clipro_nombre
  
              if (response.estado === 1) {
                this.cantSucursalesCon = this.cantSucursalesCon + 1
              }
  
              auxRes.push({
                adm_NroIntentos: element.adm_NroIntentos,
                adm_fechaDesde: element.adm_fechaDesde.substr(0, [10]),
                cli_codigo: element.cli_codigo,
                cli_usuari: element.cli_usuari,
                clipro_nombre: element.clipro_nombre,
                clisuc_codigo: element.clisuc_codigo,
                clisuc_nombre: element.clisuc_nombre,
                clisuc_numeroSucursal: element.clisuc_numeroSucursal,
                password: element.password,
                sucpgr_codigoSucursal: element.sucpgr_codigoSucursal,
                username: element.username,
                estado: response.estado,
                progSpot: progSpot,
                tiempoDesconectado: this.tiempoDesconectado(response.diferencia),
                audio:element.clisuc_idSucursal==null?'':`https://sonic.dattalive.com:${element.clisuc_idSucursal}/stream.aac`
              })
            }
          })
        })
      })
      this.tablaSucursales.rows = auxRes.sort((a, b) => a.estado - b.estado)
    },
    tiempoDesconectado (tiempoDesconectado) {
      let ultimaConexion
      // calculo los días desde la ultima conexión
      const dias = Math.trunc(((tiempoDesconectado) / 60) / 24)

      var date = moment()

      if (tiempoDesconectado === -1) {
        ultimaConexion = 'Nunca se inició sesión'
      } else {
        if (dias === 0) {
          ultimaConexion = 'Última conexión: hoy a las ' + (date.subtract(tiempoDesconectado, 'minutes').format('HH:mm'))
        } else if (dias === 1) {
          ultimaConexion = 'Última conexión: Ayer a las ' + (date.subtract(tiempoDesconectado, 'minutes').format('HH:mm'))
        } else if (dias > 1) {
          ultimaConexion = 'Última conexión: ' + (date.subtract(tiempoDesconectado, 'minutes').format('DD/MM/YYYY HH:mm'))
        }
      }

      return ultimaConexion
    },
    irSucursales () {
      this.$router.push({ path: 'sucursales' })
    },
    irMiCuenta () {
      this.$router.push({ path: 'miNegocio' })
    },
    irSpots () {
      this.$router.push({ path: 'bibliotecaSpot' })
    },
    irNotifPago () {
      this.$router.push({ path: 'notificarPago' })
    },
    crearSucursal () {
      this.crearSucursales = false // pongo en false para poder navegar
      this.$router.push({
        name: 'Creá una nueva sucursal',
        params:
          {
            idSucursal: 0
          }
      })
    },
    
    getFacturasByCli () {
      const auxRes = []
      // this.isLoading = true
      FacturaServices.getFacturasByCliente().then(res => {
        res.forEach(element => {
          /* armo un json en base al response. haciendo un substr en los elementos de tipo fecha,
            * extrayendo con substr la parte que necesito mostrar
            */
          auxRes.push({
            facli_codigo: element.facli_codigo,
            facli_periodoI: element.facli_periodoI.substr(0, [7]),
            facli_periodoF: element.facli_periodoF,
            facli_monto: element.facli_monto,
            facli_fecalt: element.facli_fecalt,
            facli_estado: element.facli_estado,
            facli_usuario: element.facli_usuario,
            facli_archivo: element.facli_archivo,
            facli_fecven: element.facli_fecven.substr(0, [10])
          })
        })
        
        this.ultimaFactura = auxRes[0]
        // this.isLoading = false
      })
    },
    getModoRepSpot () {
      clienteModoSpotService.get().then(res => {
        this.modoRepSpot = res.climod_modo
      })
    },
    consultarPaquete () {
      let cantPedidos
      let cantRealizados
      let max
      ClientePaqueteService.GetByCli().then(res => {
        cantPedidos = res.paq_maxped
        cantRealizados = res.cant_pedidos
        max = cantPedidos - cantRealizados

        this.paqueteContratado = res.paq_descri
        this.cantMaxPedidos = cantPedidos
        this.pedidosDisponibles = max
      })
    },
    getSpotsByCli () {
      const list = []

      let fechaIni,vencimiento,diasParaVenc,estadoSpot
      const fechaHoy = moment()
      let aux
      const pos = 0
      // this.isLoading = true

      SpotServices.getSpots().then(res => {
        res.forEach(element => {
          fechaIni = moment(element.spo_fecini.substr(0, 10)).format('DD-MM-YYYY')
          vencimiento = moment(element.spo_fecfin.substr(0, [10]))

          if (element.spo_tipo === 'prom') {
            aux = moment(element.spo_fecfin.substr(0, [10])).format('D/M/yyyy')
            diasParaVenc = (vencimiento.diff(fechaHoy, 'hour'))
           
            // 168 horas = 1 semana
            if (diasParaVenc < 168) {
              list.push({
                spo_nombre: element.spo_nombre,
                spo_codigo: element.spo_codigo,
                spo_fecini: fechaIni,
                spo_fecfin: aux,
                spo_source: element.spo_source,
                spo_codcli: element.spo_codcli,
                spo_tipo: element.spo_tipo
              })
            }
          }
        })
        if (list.length > 0) {
          this.haySpotsVencidos = true
        }
        this.tablaSpotsVencimiento.rows = list
        this.isLoading = false
      })
    },
    chequearLimiteSucursales (cantSucursales) {
      // buscar con un service el limite de sucursales del cliente
      const limite = this.user.cli_cantEquipos // obtengo el numero de sucursales desde el store de vuex

      if (cantSucursales === limite) {
        this.$store.state.limiteSucursal = true
      } else {
        this.$store.state.limiteSucursal = false
      }
    },
    inicializar () {
      this.cargarConenidoTabla()
      this.getFacturasByCli()
      this.getModoRepSpot()
      this.consultarPaquete()
      this.getSpotsByCli()
      
    }
  },
  beforeRouteLeave (to, from, next) {
    // si no tiene ninguna sucursal creada, no va a dejar navegar hasta crear una
    if (this.crearSucursales) {
      return
    }
    next()
  },
  created () {
    this.getCurrentUser()
    //LocutorService.getAll().then(data=>localStorage.etiqueta=JSON.stringify(data))
    this.i18n = this.$i18n
  },
  mounted () {
    this.cliSucursalesGetByCli()
    this.actualizarEstadoSucursal()
  },
  beforeDestroy () {

  }
}
</script>
<style>
</style>
