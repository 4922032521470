<template>
      <b-overlay
        :show="isLoading"
        opacity="1"
        no-wrap
        >
        <template #overlay>
          <div class="text-center">
            <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'"></scale-loader>
          </div>
        </template>
      </b-overlay>
</template>
<script>
import { BaseAlert } from '@/components'
import userservice from '../services/UserServices'
export default {
  components: {
    BaseAlert
  },
  data () {
    const i18labels = this.$t('modulo_sucursales.principal')
    const colnames = i18labels.tabla.columnas
    const i18labels1 = this.$t('modulo_spots.biblioteca_spots')
    const colnames1 = i18labels1.tabla_spots.columnas
    return {
      isLoading: false
    }
  },
      
  methods: {
    redirect(){
     let redirect = '/'
  switch (userservice.current().role) {
    case 'Reproductor':
      redirect = 'Mis spots'
      break
    case 'Operador':
      redirect= 'Noticias'
      break
  }
      this.$router.push({
          name: redirect
        })
        return false;
    }
  },
   
  mounted () {
    this.redirect()
  }
  
  }
</script>
<style>
</style>
