<template>
<!-- Page Content -->
<div class="container" id="container-login">
    <b-overlay :show="isLoading" opacity="0.25" no-wrap>
        <template #overlay>
            <div class="text-center">
                <scale-loader :loading="true" :color="'#1d8cf8'" :height="'95px'" :mensaje="textoLoader"></scale-loader>
            </div>
        </template>
    </b-overlay>
    <div class="row vertical-offset-100">
        <div class="col-md-4 offset-md-4">
            <div class="panel panel-default" id="CuadroLoginDBA">
                <div class="panel-heading">
                    <div class="cuadroLogo">
                        <img src="/imgs/logos/logologin.png" />
                    </div>
                </div>
                <div class="panel-body">
                    <form method="post" id="Login" accept-charset="UTF-8" role="form" v-on:submit.prevent="Login" class="form-lock">
                        <fieldset>
                            <div class="form-group">
                                <input class="form-control" placeholder="Usuario" name="name" v-model="usuario.name" type="text" required>
                            </div>
                            <div class="form-group">
                                <input class="form-control" placeholder="Contraseña" name="password" v-model="usuario.password" type="password" required>
                            </div>
                            <div>
                                <input class="btn btn-lg btn-info btn-block" type="submit" value="Entrar" id="btEntrar">
                            </div>
                        </fieldset>
                        <div class="text-center">
                            <a href="">Reestablecer conteseña</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- /.container -->
</template>

<script>
import UserServices from '../services/UserServices'
import {
    BaseAlert
} from '@/components'
import NotificationTemplate from '../components/NotificationPlugin/NotificationTemplate'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import handleError from '../utils/handleErrors'

export default {
    layout: 'default',
    name: 'Login',
    components: {
        BaseAlert,
        ScaleLoader

    },
    data() {
        return {
            usuario: {
                name: '',
                password: ''
            },
            notificacion: {
                mensaje: '',
                tipo: 'error'
            },
            type: ['', 'danger'],
            notifications: {
                topCenter: false
            },
            isLoading: false,
            textoLoader: 'Iniciando sesión...'
        }
    },
    methods: {
        Login() {
            this.isLoading = true
            UserServices.login(this.usuario.name, this.usuario.password).then((res) => {
                    localStorage.clear()
                    localStorage.access_token = res.access_token
                    localStorage.user = window.atob(res.access_token.split('.')[1])
                    window.location = '/'
                    // this.$router.push({path:'/'});
                })
                .catch(err => {
                    this.notificacion.mensaje = handleError.errorApi(err)
                    this.notifyVue('top', 'center', this.notificacion.mensaje)
                    this.isLoading = false
                })
        },
        notifyVue(verticalAlign, horizontalAlign, message) {
            const color = 1
            this.$notify({
                message: message,
                icon: 'tim-icons icon-alert-circle-exc',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: this.type[color],
                timeout: 0
            })
        }
    }
}
</script>
